import React, {useContext} from "react";
import Body from "./body";
import { GatsbyImage } from "gatsby-plugin-image";
import {SiteContext} from "../../context/site-context";
import "./article-full.scss"
import AuthorCallToAction from "../header/author-call-to-action";

const ArticleFull = (props) => {

  const siteContext = useContext(SiteContext);
  const i18next = siteContext.i18next;

  const imageData = props?.entity?.fieldImage?.imageGatsbyFile?.childImageSharp?.gatsbyImageData;

  const imageAlt = props?.entity?.fieldImage?.alt;
  let dateCreated = new Date(props.entity.created * 1000);

  const monthNames = [
    i18next.t('date:January'),
    i18next.t('date:February'),
    i18next.t('date:March'),
    i18next.t('date:April'),
    i18next.t('date:May'),
    i18next.t('date:June'),
    i18next.t('date:July'),
    i18next.t('date:August'),
    i18next.t('date:September'),
    i18next.t('date:October'),
    i18next.t('date:November'),
    i18next.t('date:December'),
  ];

  return (
    <>
    <div className="article-full">
      <div className="article-image">
        {imageData !== undefined &&
          <GatsbyImage alt={imageAlt} image={{...imageData}}/>
        }
        <h1>{props.entity.entityLabel}</h1>
      </div>
      <div className="article-content">
        <div className="article-meta">
          <div><span className="meta-value">{i18next.t('Created on')}:</span><span className="meta-value">{`${dateCreated.getDate()} ${monthNames[dateCreated.getMonth()]} ${dateCreated.getFullYear()}`}</span></div>
          <div><span className="meta-value">{i18next.t('By')}:</span><span className="meta-value"><a href="#author">{props.entity.entityOwner.name}</a></span></div>
          {/*{dateCreated !== dateUpdated &&*/}
          {/* <><span className="meta-value">{i18next.t('Last update')}:</span><span className="meta-value">{`${dateUpdated.getDate()} ${monthNames[dateUpdated.getMonth()]} ${dateUpdated.getFullYear()}`}</span></>*/}
          {/*}*/}
        </div>
        <Body className="article-body" html={props.entity.body?.value} />
        {/* <div className="article-body" dangerouslySetInnerHTML={{ __html: props.entity.body.processed }}></div> */}
      </div>
    </div>
    <AuthorCallToAction />
    </>
  )
};

export default ArticleFull;
