import React, {useContext} from "react"
import "./author-call-to-action.scss"
import {SiteContext} from "../../context/site-context";
import { StaticImage } from "gatsby-plugin-image"
import ArrowRight from "../../assets/svg/icons-feather/arrow-right.svg";
import Button from "../buttons/button";

export default () => {

  const siteContext = useContext(SiteContext);

  if (siteContext.i18next.language === 'nl') {
    return (
      <>
        <div className="author-call-to-action" id="author">
          <div className="inner-content">
            <div className="image">
              <StaticImage
                alt="Karolien Koolhof"
                src="../../assets/images/karolien-koolhof-2.png"
                placeholder="blurred"
                layout="constrained"
                sizes="(max-width: 480px) 200px, 400px"
              />
            </div>
            <div className="text">
              <h2>Over de auteur</h2>
              <ul>
                <li><ArrowRight/><a href="/nl/about">Karolien Koolhof</a> is coach voor introverten.</li>
                <li><ArrowRight/>Auteur van het boek <a href="https://www.bol.com/nl/nl/p/introvert-leiderschap/9300000004832863/">Introvert Leiderschap</a>.</li>
                <li><ArrowRight/>Kennismaken? Neem <a href="/nl/contact">contact</a> op.</li>
              </ul>
            </div>
          </div>
        </div>
      </>
    )
  }

  if (siteContext.i18next.language === 'en') {
    return (
      <></>
    )
  }

  return (
      <></>
  );

}
